import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["uploadBtn"];
  static values = { imageCounter: Number };

  connect() {
    console.log("Hello, Stimulus!", this.element);

    // Grab the form and the hidden file input.
    this.form = document.querySelector("#press_releases_images_form");
    this.uploadField = document.querySelector("#uploadField");

    // Save the original button text so we can revert if needed.
    this.originalText = this.uploadBtnTarget.textContent;

    // Attach the "change" event listener to the file input only once.
    if (!this.uploadField.dataset.listenerAttached) {
      this.uploadField.addEventListener("change", this.upload.bind(this));
      this.uploadField.dataset.listenerAttached = "true";
    }

    // Set the button state based on your business logic.
    this.handleDisableButton();
  }

  /**
   * Called when the user clicks the upload button.
   */
  onclick(event) {
    event.preventDefault();

    // Immediately disable the button and change its text.
    this.disableButton();
    this.uploadBtnTarget.textContent = "Uploading...";

    // Add a one‑time focus listener on the window. When the file dialog closes,
    // the window will regain focus. After a brief delay, check if the user
    // selected a file.
    window.addEventListener("focus", this.handleFocus.bind(this), { once: true });

    // Open the file selection dialog.
    this.uploadField.click();
  }

  /**
   * When the window regains focus (i.e. after the file dialog is closed), wait a short
   * moment and then check whether a file was selected. If not, revert the button.
   */
  handleFocus() {
    setTimeout(() => {
      // If no file was selected (i.e. the file input’s value is empty), then the user canceled.
      if (!this.uploadField.value) {
        // Revert the button text to its original value and re-enable it.
        this.uploadBtnTarget.textContent = this.originalText;
        this.enableButton();
      }
    }, 100); // 100ms delay allows any "change" event (if a file was selected) to fire first.
  }

  /**
   * Called when the file input’s change event fires (i.e. when a file is selected).
   */
  async upload(event) {
    event.preventDefault();

    const formData = new FormData(this.form);

    try {
      const response = await fetch(this.form.action, {
        method: this.form.method,
        body: formData,
        headers: {
          "Accept": "text/vnd.turbo-stream.html"
        }
      });

      const turboStreamResponse = await response.text();
      Turbo.renderStreamMessage(turboStreamResponse);

      // Clear the file input so that the same file can be selected again if needed.
      this.uploadField.value = "";
    } catch (error) {
      console.error("Failed to submit the form:", error);
    } finally {
      // In your current business logic, you may disable or enable the button
      // based on the image counter or other state.
      this.handleDisableButton();
    }
  }

  /**
   * Enables or disables the button based on custom logic (e.g. maximum image count reached).
   */
  handleDisableButton() {
    // Example logic: disable the button if imageCounterValue is 7 or more.
    const actions = [this.enableButton, this.disableButton];
    const isAtLimit = this.imageCounterValue >= 7;
    actions[Number(isAtLimit)].call(this);
  }

  disableButton() {
    if (this.uploadBtnTarget.disabled === true) return;
    this.uploadBtnTarget.disabled = true;
  }

  enableButton() {
    if (this.uploadBtnTarget.disabled === false) return;
    this.uploadBtnTarget.disabled = false;
  }
}
